.phone-input-wrapper {
  width: 100%;
  height: 40px;
  border-radius: 80px;
  overflow: hidden;
  border: 1px solid var(--lightblue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: text;
}

.focus {
  border: 1px solid var(--inactiveText);
}

.phone-input-divider {
  width: 1px;
  height: 80%;
  background-color: #a2c1c7;
  margin: 10px 0;
}

.phone-input {
  font-family: "Poppins";
  width: 80%;
  height: 50px;
  padding-left: 5%;
  border: 0 transparent;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  color: var(--primaryblue);
}

.country-code-input {
  width: 20%;
  height: 50px;
  padding-left: 5%;
  border: 0 transparent;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  color: var(--primaryblue);
}

::placeholder {
  font-family: "Poppins";
  font-weight: 300;
  color: #888;
}

.country-select {
  overflow: visible;
  border: none;
}

.PhoneInput {
  width: 100%;
  height: 100%;
  padding: 0 12px;
}

.PhoneInputInput {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  font-family: "Poppins";
  font-size: 16px;
  color: var(--primaryblue);
  border: 0 transparent;
  outline: none;
  background-color: transparent;
}
