.step-content {
    width: 100%;
    height: 100%;
}

.title-container {
    height: 20%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    /* background-color: rgba(192, 255, 193, 0.8); */
}

.title {
    color: var(--darkBlueBackground);
    font-size: 25px;
    font-weight: 300;
    margin-left: 5%;
    margin-top: 5%;
}
    
.comment-container {
    height: 80%;
    width: 100%;
    /* background-color: rgba(122, 134, 209, 0.8); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-area {
    padding: 2%;
    width: 80%;
    height: 60%;
    font-family: 'Poppins', sans-serif;
    resize: none;
}