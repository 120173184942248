.button {
    /* padding-top: 33px; */
    padding: 18px 5px;
    border-radius: 32px;
    height: 20px;
    width: 130px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    transition: opacity 0.3s;
  }

  .button:disabled {
    background-color: #A2C1C7;
  }
  
  .buttonRegular {
    background-color: #005E7E;
    color: white;
  }
  
  .buttonOutline {
    background-color: transparent;
    border: 1px solid #005E7E;
    color: #00344f;
  }
  
  .buttonWhite {
    background-color: white;
    border: 1px solid #00344f;
    color: #00344f;
  }
  
  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-text {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    text-align: center;
  }
  .text-container {
    justify-content: center;
  }
  .textRegular {
    color: white;
  }
  .textRegular:disabled {
    color: white;
  }
  
  .textOutline {
    color: #00344f;
  }

  .textOutline.disabled {
    color: white;
  }
  
  .textWhite {
    color: #00344f;
  }

  .full-width {
    width: 100%;
  }
  