/* body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #043954;
} */

.animated-view::-webkit-scrollbar {
  display: none;
}
.animated-view {
  /* width: 33%;
  max-height: 66%; */
  width: 100%;
  height: 80%;
  padding: 12px 24px;
  background-color: white;
  border-radius: 16px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  overflow-y: auto;
}

.inner-top-text {
  font-family: "Poppins", sans-serif; /* Replace with your FONTS.medium */
  color: #043954; /* Replace with your COLORS.secondary */
  font-size: 20px;
  /* margin-bottom: 16px; */
  margin: 0;
  padding: 0;
}

.inner-restaurant-name {
  font-family: "Poppins", sans-serif; /* Replace with your FONTS.medium */
  color: #043954; /* Replace with your COLORS.secondary */
  font-size: 15px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.inner-restaurant-address {
  font-family: "Poppins", sans-serif; /* Replace with your FONTS.medium */
  font-size: 13px;
  color: var(--lightblue);
  padding-top: 0;
  margin-top: 0;
}

.scroll-container {
  display: flex;
  flex-direction: column;
}

.step-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.inner-step-text {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  color: var(--lightblue);
  font-size: 14px;
  width: 140px;
}

.inner-description-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #043954; /* Replace with your COLORS.secondary */
  font-size: 14px;
}

@media (max-width: 768px) {
  .animated-view {
    width: 90%;
    max-height: 80%;
    padding: 16px;
  }

  .inner-top-text {
    font-size: 18px;
  }

  .inner-step-text {
    font-size: 16px;
  }

  .inner-description-text {
    font-size: 14px;
  }
}
