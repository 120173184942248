.input-wrapper {
    width: 100%;
    height: 40px;
    border-radius: 80px;
    overflow: hidden;
    border: 1px solid var(--lightblue);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: text;
  }

  .focus {
    border: 1px solid var(--inactiveText);
  }
  
  .input {
    font-family: 'Poppins';
    width: 100%;
    height: 50px;
    padding-left: 5%;
    border: 0 transparent;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    color: var(--primaryblue);
  }
  
  ::placeholder {
    color: #888;
    font-family: 'Poppins';
  }
  