.full-page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  gap: 3%;
}
@media (max-width: 599px) {
  .container {
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    justify-content: space-between;
  }
  .container::-webkit-scrollbar{
    display: none;
  }
  .display-container, .step-container {
    width: 100% !important;
    height: 50% !important;
  }
  .step-container {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    z-index: 1000;
  }
}
.display-container, .step-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
  height: 60%;
  min-width: 300px;
}

.display-container {
  background-color: var(--darkBlueBackground);
}

.step-container {
  border-radius: 6%;
  background-color: white;
  display: flex;
  justify-content: space-around;
  
}

.step-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  background-color: transparent;
}

.step-navigator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20%;
}

.textarea {
  resize: none;
}

button {
  cursor: pointer;
}

.logo-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 2%;
  padding-right: 2%;
}