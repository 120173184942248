.full-page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

@media (max-width: 599px) {

}

.success-wrapper {
  width: 30%;
  height: 70%;
  min-width: 400px;
  border-radius: 7%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;
  /* justify-content: space-around; */
}
.success-top {
  /* background-color: rgba(127, 255, 212, 0.8); */
  width: 80%;
  height: 55%;
}
.success-image {
  /* background-color: rgb(121, 237, 112); */
  width: 100%;
  height: 60%;
}
.success-message {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10%;
}
.success-title {
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  text-align: center;
}
.restaurant-name {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  text-align: center;
  color: #005E7E;
  font-weight: 400;
}
.restaurant-address {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #00ADCB;
}

.success-link {
  /* background-color: rgba(255, 225, 127, 0.8); */
  width: 80%;
  height: 27%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

button {
  cursor: pointer;
}

.logo-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding-bottom: 2%;
  padding-right: 2%;
}
.disclaimer {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  text-align: center;
  font-weight: 100;
  color: var(--darkBlueBackground);
}

.logo-solid-container {
  height: 25px;
  object-fit: contain;
}

.link-button {
  background-color: var(--darkBlueBackground);
  width: 70%;
  border-radius: 25px;
  margin-top: 5%;
  border: none !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.link-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  letter-spacing: 0.5px;
  padding-right: 3%;
  font-size: 13px;
  color: white;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}