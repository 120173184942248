.full-width {
    width: 100%;
    margin: 0 !important;
}

.select-button-text {
    font-size: 10px;
    text-align: center;
    color: var(--primaryblue);
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    padding: 2px 10px;
}

.select-button-text.btn-selected {
    color: white !important;
}

.select-button-box {
    border-radius: 25px;
    padding: 5px;
    margin: 0 5px;
    padding-left: 1%;
    padding-right: 1%;
    min-width: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: transparent;
    border: 1px solid var(--primaryblue);
    cursor: pointer;
    }
    
.select-button-box.btn-selected {
    background-color: var(--lightblue);
    border: 1px solid var(--lightblue);
}
