.virtualized-list-container {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    /* padding: 0 10px; */
    /* background-color: darksalmon; */
    display: flex;
    justify-content: center;
  }
  
  .virtualized-list-inner {
    position: relative;
    width: 100%;
  }
  
  .virtualized-list-content {
    position: absolute;
    width: 100%;
  }
  
  .virtualized-list-item {
    width: 100%;
    padding: 5px 0;
    height: "auto";
  }
  
  .virtualized-list-container::-webkit-scrollbar {
    display: none;
  }
  