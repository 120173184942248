.date-input-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  width: 100%;
  height: 100%;
  /* color: black; */
  /* background-color: rgba(165, 42, 42, 0.8); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-container {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  /* background-color: rgba(85, 97, 236, 0.8); */
}

.title {
  color: var(--darkBlueBackground);
  font-size: 25px;
  font-weight: 300;
  margin-left: 5%;
  margin-top: 5%;
  /* background-color: rgba(122, 134, 209, 0.8); */
}

.calendar-container {
  width: 80%;
  height: 80%;
  margin-top: 2%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  object-fit: contain;
  /* background-color: rgba(255, 219, 127, 0.8); */
}

.react-calendar {
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  width: 80%;
}

.react-calendar__tile {
  background: none;
  border: none;
  color: inherit;
  outline: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
}

.react-calendar__tile--active {
  background-color: var(--lightblue);
  color: white;
  border-radius: 50%;
}

.react-calendar__tile--now {
  border: none;
}

.react-calendar__tile:disabled {
  color: #cccccc;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: white;
}

.react-calendar__tile--available:enabled:hover {
  background-color: var(--lightblue);
  color: white;
  border-radius: 50%;
}

.react-calendar__tile--available:enabled:focus {
  background-color: var(--lightblue);
  color: white;
  border-radius: 50%;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  font-family: "Poppins", sans-serif;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-size: 20px;
  color: var(--lightblue);
  font-weight: 300;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation button {
  background: none;
  border: none;
  /* font-size: 1rem;
  color: inherit; */
  cursor: pointer;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}
