.party-input-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.no-tables-available-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #888888;
  font-size: 16px;
  text-align: center;
  padding: 24px;
}

.option-container {
  width: 100%;
  height: 100%;
}

.title-container {
  height: 20%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  /* background-color: rgba(192, 255, 193, 0.8); */
}

.step-title {
  color: var(--darkBlueBackground);
  font-size: 1.2em;
  font-weight: 300;
  margin-left: 5%;
  margin-top: 5%;
  /* background-color: rgba(122, 134, 209, 0.8); */
}

.party-size-select {
  /* background-color: aquamarine; */
  width: 90%;
  height: 80%;
  margin-left: 5%;
}
