:root {
  --primaryblue: #005E7E;
  --lightblue: #00ADCB;
  --secondary: #096285;
  --muted: #e4f1f3;
  --mutedDark: #d0e7eb;
  --inactiveText: #05e0ee;
  --darkBlueBackground: #00344f;
  --font-regular: 'Poppins', sans-serif 400;
  --font-semiBold: 'Poppins', sans-serif 600;
}

body, html, #root {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  background-color: var(--darkBlueBackground);
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
