.scroll-view {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 599px) {
  .scroll-view {
    width: 80%;
    padding-top: 5%;
    display: flex;
    justify-content: center;
  }
}

.table-input-container::-webkit-scrollbar {
  display: none;  
}

.form-info-box {
  padding-bottom: 2px;
}
