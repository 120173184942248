.step-content {
  width: 100%;
  height: 100%;
}

.title-container {
  height: 20%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
}

.title {
  color: var(--darkBlueBackground);
  font-size: 25px;
  font-weight: 300;
  margin-left: 5%;
  margin-top: 5%;
}

.client-form {
  background-color: transparent;
  padding: 20px;
  width: 80%;
  height: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.form-container {
  height: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.errorDisplay {
  color: red;
  font-size: 14px;
  font-weight: 300;
  margin-top: 4px;
}
