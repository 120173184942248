.allergies-container {
  /* padding: 24px; */
  width: 100%;
  height: 100%;
}

.title-container {
  height: 20%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  /* background-color: rgba(192, 255, 193, 0.8); */
}
  
.allergies-title {
  color: var(--darkBlueBackground);
  font-size: 25px;
  font-weight: 300;
  margin-left: 5%;
  margin-top: 5%;
}
  
.allergies-content {
  height: 75%;
  width: 80%;
  margin-left: 5%;
  /* background-color: rgba(122, 134, 209, 0.8); */
}

.select-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
  gap: 5px;
}

.select-button {
  display: inline-block;
}

.box {
  padding: 10px 20px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid var(--primaryblue);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.box.selected {
  background-color: var(--lightblue);
  color: var(--white);
}

.select-button-text {
  font-size: 18px;
  text-align: center;
}

.select-button-text.selected {
  font-family: var(--font-regular);
  color: var(--white);
}
