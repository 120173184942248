.reservation-display-container {
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.text-container {
  padding: 5px 10px;
  margin-left: 5px;
  letter-spacing: 0.15px;
  width: 15vw;
  min-width: 180px;
}

.text-container-selected {
  background-color: var(--primaryblue);
  border-radius: 40px;
}

.text {
  color: var(--lightblue);
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.text-highligted {
  color: white;
  font-family: 'Poppins', sans-serif;
}
