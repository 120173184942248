.header-box {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-text {
  /* font-family: var(--font-regular); */
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  font-weight: 100;
  color: var(--muted);
  margin: 0;
}

.restaurant-data {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subtitle-text {
  /* font-family: var(--font-regular); */
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  letter-spacing: 0.6px;
  font-size: 13px;
  color: var(--inactiveText);
  margin: 0;
  padding: 0;
}

.name {
  color: var(--lightblue) !important;
  font-weight: 400;
  font-size: 14px;
}
