.time-input-container {
  /* padding: 24px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 5%; */
  }
  
  .time-input-container-loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title-container {
    height: 20%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: flex-start;
    /* background-color: rgba(192, 255, 193, 0.8); */
  }
    
  .title {
    color: var(--darkBlueBackground);
    font-size: 25px;
    font-weight: 300;
    margin-left: 5%;
    margin-top: 5%;
    /* background-color: rgba(122, 134, 209, 0.8); */
  }

  .time-select {
    height: 80%;
    width: 90%;
    margin-left: 5%;
    /* background-color: aquamarine; */
  }

  .time-frame-box {
    justify-content: center;
    padding: 2% 0;
  }
  
  .time-frame-list-container {
    display: flex;
    flex-wrap: wrap;
  }

  .slot-title {
    font-size: 14px;
    margin-bottom: 3%;
  }

  .head-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #096285;
    font-size: 18px;
    margin-left: 10px;
  }
  
  .no-booking-available-text {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #888888;
    text-align: center;
    font-size: 16px;
    /* padding: 24px; */
  }
  
  .icon {
    font-family: 'Ionicons';
  }
  